import cn from 'classnames'

import { Icon, IconNames } from 'ui/icon'

import s from './styles.module.scss'

export type CategoryItemProps = {
  iconName: IconNames
  label: string
  onClick?: () => void
  loading?: boolean
}

const CategoryItem = ({ iconName, label, loading, onClick }: CategoryItemProps) => {
  if (loading) {
    return (
      <div className={cn(s.categoryCard, s.loading)}>
        <div className={s.iconContainer} />
        <span className={cn(s.label)} />
      </div>
    )
  }

  return (
    <button type="button" onClick={onClick} className={cn(s.categoryCard)}>
      <div className={s.iconContainer}>
        <Icon name={iconName || 'sparkle-two'} />
      </div>
      <span className={cn(s.label)}>{label}</span>
    </button>
  )
}

export { CategoryItem }
