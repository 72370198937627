import React, { FC } from 'react'
import Link from 'next/link'

import { CategoryItem } from 'components/category-item'

import { IconNames } from 'brand-assets/icon-lib'

type InterestCardProps = {
  loading?: boolean
  data?: {
    label: string
    url: string
    icon: IconNames
  }
}

const InterestCard: FC<InterestCardProps> = ({ loading, data }) => {
  if (loading) {
    return <CategoryItem loading iconName="air-balloon" label="" />
  }

  if (!data) return null

  const { label, url, icon } = data || {}

  return (
    <Link passHref href={`/${url}`}>
      <a>
        <CategoryItem iconName={icon} label={label} />
      </a>
    </Link>
  )
}

export { InterestCard }
