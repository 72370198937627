import cn from 'classnames'

import { Button } from 'ui/button'
import { Icon } from 'ui/icon'

import { HowItWorksModal } from 'components/how-it-works-modal'

import { useToggle } from 'lib/hooks/useToggle'
import useDateCurrency from 'lib/hooks/useDateCurrency'
import useTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'

import s from './styles.module.scss'

const HowItWorks = ({
  textAlign,
  variant = 'booking',
  layout = 'square',
  contentVariant = 'best-price-guaranteed',
  paymentBreakdown,
}: {
  textAlign?: 'left' | 'center' | 'right'
  variant: 'pdp' | 'booking' | 'value-props'
  layout?: 'rounded-top' | 'square'
  contentVariant?: 'best-price-guaranteed' | 'partial-payment'
  paymentBreakdown?: PaymentBreakdown
}) => {
  const { trackEvent } = useAppData()
  const { t } = useTranslation(['common', 'product'])
  const { formatNumberDecimal } = useDateCurrency()
  const { depositType, depositValue, finalPaymentDueDays } = paymentBreakdown || {}
  const [isOpen, { onOpen, onClose }] = useToggle()

  const depositLabel = depositValue
    ? depositType === 'percentage'
      ? `${formatNumberDecimal(depositValue)}%`
      : `${depositType} ${formatNumberDecimal(depositValue)}`
    : ''

  return (
    <>
      {variant === 'value-props' ? (
        <Button variant="link-tertiary" size="small" onClick={onOpen}>
          {t('action.learnMore')}
        </Button>
      ) : (
        <div
          className={cn(
            s.howItWorks,
            { [s[`text-${textAlign}`]]: !!textAlign },
            s[`variant-${variant}`],
            s[layout]
          )}
          onClick={onOpen}
        >
          <div className={s.label}>
            {!!paymentBreakdown && contentVariant === 'partial-payment'
              ? t('t.bookNowPayOnlyPartToday', {
                  deposit: depositLabel,
                })
              : t('action.bestPriceGuaranteed', { ns: 'common' })}
          </div>
          <Icon name="information-outline" size="mini" />
        </div>
      )}

      <HowItWorksModal
        variant={contentVariant}
        open={isOpen}
        onClose={onClose}
        metaData={{ depositLabel, finalPaymentDueDays }}
        trackEvent={trackEvent.current}
      />
    </>
  )
}

export { HowItWorks }
