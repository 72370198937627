import React, { FC, useMemo } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import useDateCurrency from 'lib/hooks/useDateCurrency'

import { FALLBACK_LOCALE } from 'lib/constants'

import localeInfo from '../../locale-info.json'

interface MetaTagsProps {
  type?: string
  overridePath?: string
  componentContent?: any
}

const MetaTags: FC<MetaTagsProps> = ({
  type,
  overridePath,
  componentContent: {
    title,
    ogTitle,
    description,
    ogDescription,
    ogImage,
    h1,
    ogProductAvailability,
    ogProductId,
    ogProductPriceCurrency,
    ogProductPriceAmount,
    ogBrand,
    ogProductLocale,
    ogLocale,
    ogProductCondition,
    noIndex,
  } = {},
}) => {
  // asPath = with query string
  const { locale, asPath: path } = useRouter()
  const { formatNumberDecimal } = useDateCurrency()

  const [pathWithoutQuery] = overridePath ? [overridePath] : path.split('?')
  const [, queryString] = path.split('?')
  const fullUrlWithQuery = `${process.env.NEXT_PUBLIC_HOST}/${locale}${
    overridePath ? `${overridePath}${queryString ? '?' + queryString : ''}` : path
  }`

  const canonicalTags = useMemo(() => {
    const canonicalUrl = `${process.env.NEXT_PUBLIC_HOST}/${locale}${pathWithoutQuery}`

    const tags = [<link rel="canonical" href={canonicalUrl} key="canonical" />]

    localeInfo.supportedLocales?.map?.((key: string) => {
      const alternateUrl = `${process.env.NEXT_PUBLIC_HOST}/${key}${pathWithoutQuery}`

      return tags.push(<link rel="alternate" href={alternateUrl} hrefLang={key} key={key} />)
    })

    tags.push(
      <link
        key="x-default"
        rel="alternate"
        href={`${process.env.NEXT_PUBLIC_HOST}/${FALLBACK_LOCALE}${pathWithoutQuery}`}
        hrefLang="x-default"
      />
    )

    return tags
  }, [pathWithoutQuery, locale])

  return (
    <>
      <Head>
        {noIndex && (
          <>
            <meta name="robots" content="noindex" />
            <meta name="googlebot" content="noindex" />
          </>
        )}
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {type && <meta property="og:type" content={type} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        <meta property="og:url" content={fullUrlWithQuery} />
        <meta property="twitter:url" content={fullUrlWithQuery} />
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
        {ogLocale && <meta property="og:locale" content={ogLocale} />}
        {ogImage && <meta name="twitter:image" content={ogImage} />}
        {ogProductAvailability && <meta property="product:availability" content={ogProductAvailability} />}
        {ogProductId && <meta property="product:retailer_item_id" content={ogProductId} />}
        {ogProductPriceCurrency && (
          <meta property="product:price:currency" content={ogProductPriceCurrency} />
        )}
        {ogProductPriceAmount && (
          <meta property="product:price:amount" content={`${formatNumberDecimal(ogProductPriceAmount)}`} />
        )}
        {ogBrand && <meta property="product:brand" content={ogBrand} />}
        {ogProductLocale && <meta property="product:locale" content={ogProductLocale} />}
        {ogProductCondition && <meta property="product:condition" content={ogProductCondition} />}
        <meta name="twitter:card" content="summary_large_image" />
        {canonicalTags}
      </Head>
      {h1 && <h1 className="visually-hidden">{h1}</h1>}
    </>
  )
}

export { MetaTags }
