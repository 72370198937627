import cn from 'classnames'

import { Image } from 'ui/image'
import { Icon } from 'ui/icon'

import { ExposureTracker } from 'components/exposure-tracker'
import { RichText } from 'components/rich-content/RichText'

import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'
import { CAMPAIGN_TYPE } from 'lib/constants'

import s from './styles.module.scss'

const CampaignBanner = ({ trackEvent }: { trackEvent?: TrackEventType }) => {
  const { campaignData } = useAppData()
  const TYPE_BANNER = 'BANNER'

  if (
    campaignData?.campaignType !== CAMPAIGN_TYPE.SEASONAL_CAMPAIGN ||
    campaignData?.campaignMetaData?.displayed_as?.indexOf?.(TYPE_BANNER) === -1
  )
    return null

  const clickableLink = campaignData?.content?.link?.url
  const hasClickableLink = !!clickableLink
  const hasImage =
    !!campaignData.mediaData &&
    campaignData?.campaignMetaData?.display_image_on?.indexOf?.(TYPE_BANNER) !== -1
  const theme = campaignData?.campaignMetaData?.bg_color?.slice(1)

  return (
    <div
      className={cn(s.campaignBanner, s[`_theme_${theme}`], {
        [s._loading]: false,
        [s.isClickable]: hasClickableLink,
      })}
      onClick={
        hasClickableLink
          ? (e) => {
              if (e.target instanceof HTMLAnchorElement) return
              trackEvent?.({
                eventType: EVENTS.TYPE.CLICK,
                attributeValue: {
                  link: clickableLink,
                },
              })
              window.open(clickableLink, '_blank')
            }
          : undefined
      }
    >
      <div className={s.bannerBox}>
        <div className={s.bannerContent}>
          <h2 className={s.title}>
            <RichText text={campaignData?.content?.title} className={s.ritchText} />
          </h2>
          <p className={s.text}>
            <RichText text={campaignData?.content.description} className={s.ritchText} />
          </p>
        </div>
        {hasImage && (
          <div className={s.image}>
            <Image
              src={campaignData.mediaData}
              size="xsmall"
              alt={`campaign-${campaignData.campaignId}`}
              layout={'fill'}
            />
          </div>
        )}
        {hasClickableLink && (
          <div className={s.click}>
            <Icon name="caret-right" size="small" />
          </div>
        )}
      </div>
      <ExposureTracker
        onExposure={() => {
          trackEvent?.({
            attributeId: EVENTS.SEASONAL_CAMPAIGN.BANNER,
            attributeType: EVENTS.ATTRIBUTES_TYPE.SECTION,
            eventType: EVENTS.TYPE.EXPOSURE,
            attributeValue: {
              campaignId: campaignData?.campaignId,
            },
          })
        }}
      />
    </div>
  )
}

export { CampaignBanner }
